import React from "react";
import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
//import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import image from "../../Assets/section1.jpg";

const Contact = () => {
  return (
    <Box sx={{ p: { xs: "1%", sm: "3% 0px" } }}>
      <Box
        sx={{
          display: "flex",
          gap: { xs: "20px", sm: "40px" },
          flexDirection: { xs: "column", sm: "row", md: "row" },
        }}
      >
        <Box sx={{ width: { xs: "100%", sm: "", md: "30%" } }}>
          <img
            src={image}
            alt="this is an "
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
        <Box sx={{ width: { sx: "99%", sm: "80%", md: "50%" }, p: "3%" }}>
          <Box sx={{ pb: { xs: "9px", sm: "50px" } }}>
            <Typography
              sx={{
                fontSize: { xs: "10px", sm: "15px", color: "#9AA691" },
                fontWeight: 600,
                fontFamily: "Montserrat",
              }}
            >
              Contact Us
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "25px", sm: "30px" },
                fontWeight: 700,
                fontFamily: "Montserrat",
              }}
            >
              Get In Touch
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: { xs: "17px", sm: "20px" },
              fontWeight: 500,
              fontFamily: "Montserrat",
            }}
          >
            Leave us a message
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Box
              sx={{
                width: { xs: "90%", sm: "70%", md: "50%" },
                ml: { xs: "5%", sm: "0.5px" },
                fontWeight: 500,
                fontFamily: "Montserrat",
              }}
            >
              <form
                action="https://formsubmit.co/info@pivotworkshr.org"
                method="POST"
              >
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  size="medium"
                  name="name"
                  id="Name"
                  label="Your Name"
                  autoFocus
                  sx={{ m: "4% 1px" }}
                />
                <TextField
                  label="Email Address"
                  variant="outlined"
                  required
                  fullWidth
                  size="medium"
                  name="email"
                  id="Email"
                  autoFocus
                  sx={{ m: "3% 1px" }}
                />
                <TextField
                  select
                  label="Select Service"
                  variant="outlined"
                  required
                  fullWidth
                  size="medium"
                  name="service"
                  id="Service"
                  autoFocus
                  sx={{ m: "3% 1px" }}
                >
                  <MenuItem value="advance_your_career">
                    Advance your Career
                  </MenuItem>
                  <MenuItem value="software_engineers">
                    Software Engineers
                  </MenuItem>
                  <MenuItem value="recruit_for_your_organization">
                    Recruit for your Organization
                  </MenuItem>
                  <MenuItem value="hr_advisory_services">HR Advisory Services</MenuItem>
                </TextField>
                <TextField
                  label="Your Message"
                  variant="outlined"
                  required
                  multiline
                  fullWidth
                  rows={5}
                  name="message"
                  id="Message"
                  autoFocus
                  sx={{ m: "3% 1px" }}
                />
                <Button
                  type="submit"
                  fullWidth
                  sx={{
                    fontSize: { xs: "20px", sm: "15px", md: "20px" },
                    backgroundColor: "#9AA691",
                    color: "#fff",
                    p: { xs: "10px 15px", sm: "5px 30px" },
                    mt: "3%",
                    textTransform: "unset",
                    pb: "30px",
                    fontWeight: 500,
                    fontFamily: "Montserrat",
                  }}
                >
                  Send
                </Button>
              </form>
            </Box>
            <Box
              sx={{
                width: { xs: "70%", sm: "50%", md: "40%" },
                ml: { xs: "10%", sm: "0.5px" },
              }}
            >
              <Box sx={{ display: "flex", gap: "8px", p: "3% 1px" }}>
                <LocationOnOutlinedIcon />
                <Typography>Westlands, Nairobi</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "8px", p: "3% 1px" }}>
                <LocalPhoneOutlinedIcon />
                <Typography>+254743218671</Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "8px", p: "3% 1px" }}>
                <MailOutlineOutlinedIcon />
                <Typography>info@pivotworkshr.org</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  m: { xs: "5% 1px", sm: "5% 1%" },
                }}
              >
                <a
                  href="https://www.linkedin.com/company/pivotworkshr/"
                  target="_blank"
                >
                  <LinkedInIcon
                    sx={{
                      color: "#032d2e",
                      p: { xs: "", sm: "" },
                      fontSize: { xs: "24px", sm: "35px" },
                    }}
                  />
                </a>

                <a
                  href="https://twitter.com/pivotworkshr?s=21&t=744hFtUA20Cl0Ho8d2-aag"
                  target="_blank"
                >
                  <TwitterIcon
                    sx={{
                      color: "#032d2e",
                      m: { xs: "1px 30px", sm: "1px 50px" },
                      fontSize: { xs: "24px", sm: "35px" },
                    }}
                  />
                </a>
                <a
                  href="https://instagram.com/pivotworkshr?igshid=MzRlODBiNWFlZA=="
                  target="_blank"
                >
                  <InstagramIcon
                    sx={{
                      color: "#032d2e",
                      p: { xs: "", sm: "" },
                      fontSize: { xs: "24px", sm: "35px" },
                    }}
                  />
                </a>
                {/* <WhatsAppIcon
                  sx={{
                    p: { xs: "", sm: "" },
                    fontSize: { xs: "24px", sm: "35px" },
                  }}
                /> */}
              </Box>
              <Box sx={{ width: { sx: "100%", sm: "100%" } }}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7977.682238016946!2d36.805651326258456!3d-1.268124696300246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f173c80470cb7%3A0xbe827c7d074a9cb2!2sParklands%20Shade%20Hotel%20K1%20Clubhouse!5e0!3m2!1sen!2ske!4v1679495668559!5m2!1sen!2ske"
                  // width="350"
                  // height="160"
                  title="map"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
