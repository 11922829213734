import React, { useEffect, useState } from "react";
import "./service.css";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Container,
  Button,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CardDetails from "./CardDetails";

const Services = () => {
  const navigate = useNavigate();
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (cardId) => {
    navigate(`/card-details/${cardId}`); // Pass the cardId as a parameter to the URL
  };

  useEffect(() => {
    if (selectedCard) {
      navigate(`/card-details/${selectedCard}`); // Navigate to the card details route with the selectedCard
    }
  }, [selectedCard, navigate]);

  return (
    <Box id="services">
      <Box id="s_top" sx={{ backgroundColor: "#9AA691" }}>
        <Container sx={{ pt: "1%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ color: "white", pb: "20px" }}>
              <Typography
                sx={{
                  fontSize: { xs: "40px", sm: "43px", md: "50px" },
                  fontWeight: 700,
                  fontFamily: "Montserrat",
                }}
              >
                Our Services
              </Typography>
            </Box>
            <Box
              sx={{
                width: { xs: "300px", md: "500px" },
                color: "white",
                lineHeight: "25px",
                fontWeight: 400,
                fontFamily: "Montserrat",
                pb: "50px",
                textAlign: "center",
              }}
            >
              We always exceed expectations offering tailored, innovative HR business advisory services to drive growth and success.
            </Box>
          </Box>
          <Grid
            container
            spacing={2}
            pb="5%"
            sx={{ display: "flex", flexWrap: "wrap" }}
          >
            <Grid
              item
              xs={6}
              sm={3}
              className="card"
              onClick={() => handleCardClick("1")}
            >
              <Paper
                sx={{
                  p: "7%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "100%",
                  // width:"190px",
                  cursor: "pointer",
                  textAlign: "center",
                  color: "#fff",
                  backgroundColor: "#002223",
                }}
              >
                <Box>
                  <ArrowForwardIcon
                    sx={{ fontSize: { xs: "50px", sm: "70px" } }}
                  />
                  <Typography
                    sx={{
                      fontSize: { xs: "22px", sm: "25px", md: "22px" },
                      p: " 1% 0px",
                      fontWeight: 700,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Advance Your Career
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", sm: "18px" },
                      p: "5px 1px",
                      fontWeight: 400,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Elevate your career with precision.
                  </Typography>
                </Box>
                <Button
                  className="lbtn"
                  sx={{
                    color: "#fff",
                    textDecoration: "underline",
                    textTransform: "unset",
                  }}
                >
                  Learn More
                </Button>
              </Paper>
            </Grid>
            <Grid
              item
              xs={6}
              sm={3}
              className="card"
              onClick={() => handleCardClick("2")}
            >
              <Paper
                sx={{
                  p: "7%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  height: "100%",
                  textAlign: "center",
                  color: "#fff",
                  backgroundColor: "#002223",
                }}
              >
                <Box>
                  <ArrowForwardIcon
                    sx={{ fontSize: { xs: "50px", sm: "70px" } }}
                  />
                  <Typography
                    sx={{
                      fontSize: { xs: "22px", sm: "23px", md: "24px" },
                      p: " 1% 0px",
                      fontWeight: 700,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Recruit for your Organization
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", sm: "18px" },
                      p: "5px 1px",
                      fontWeight: 400,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Power your organization with exceptional talent.
                  </Typography>
                </Box>
                <Button
                  className="lbtn"
                  sx={{
                    color: "#fff",
                    textDecoration: "underline",
                    textTransform: "unset",
                  }}
                >
                  Learn More
                </Button>
              </Paper>
            </Grid>
            <Grid
              item
              xs={6}
              sm={3}
              className="card"
              onClick={() => handleCardClick("3")}
            >
              <Paper
                sx={{
                  p: "7%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "100%",
                  // width:"190px",
                  cursor: "pointer",
                  textAlign: "center",
                  color: "#fff",
                  backgroundColor: "#002223",
                }}
              >
                <Box>
                  <ArrowForwardIcon
                    sx={{ fontSize: { xs: "50px", sm: "70px" } }}
                  />
                  <Typography
                    sx={{
                      fontSize: { xs: "22px", sm: "23px", md: "25px" },
                      p: " 1% 0px",
                      fontWeight: 700,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Software Engineers
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", sm: "18px" },
                      p: "5px 1px",
                      fontWeight: 400,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Empower your tech innovation journey.
                  </Typography>
                </Box>
                <Button
                  className="lbtn"
                  sx={{
                    color: "#fff",
                    textDecoration: "underline",
                    textTransform: "unset",
                  }}
                >
                  Learn More
                </Button>
              </Paper>
            </Grid>
            <Grid
              item
              xs={6}
              sm={3}
              className="card"
              onClick={() => handleCardClick("4")}
            >
              <Paper
                sx={{
                  p: "7%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "100%",
                  // width:"190px",
                  cursor: "pointer",
                  textAlign: "center",
                  color: "#fff",
                  backgroundColor: "#002223",
                }}
              >
                <Box>
                  <ArrowForwardIcon
                    sx={{ fontSize: { xs: "50px", sm: "70px" } }}
                  />
                  <Typography
                    sx={{
                      fontSize: { xs: "22px", sm: "23px", md: "25px" },
                      p: " 1% 0px",
                      fontWeight: 700,
                      fontFamily: "Montserrat",
                    }}
                  >
                    HR Advisory Services 
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: "16px", sm: "18px" },
                      p: "5px 1px",
                      fontWeight: 400,
                      fontFamily: "Montserrat",
                    }}
                  >
                    Improve your organizational performance and facilitate exponential growth
                  </Typography>
                </Box>
                <Button
                  className="lbtn"
                  sx={{
                    color: "#fff",
                    textDecoration: "underline",
                    textTransform: "unset",
                  }}
                >
                  Learn More
                </Button>
              </Paper>
            </Grid>
          </Grid>
          {selectedCard && <CardDetails cardId={selectedCard} />}
        </Container>
      </Box>
      {/* <Divider sx={{color:"white"}}/> */}
      <Box className="bottom" sx={{}}>
        <Container>
          <Box
            sx={{
              // height: "30vh",
              p: "8%",
              // backgroundColor: "#E3BC9A",
              color: "white",
              pb: "7%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "22px", sm: "30px", md: "30px", lg: "34px" },
                  fontWeight: 400,
                  fontFamily: "Montserrat",
                  textAlign: "center",
                  color: "#9AA691",
                  p: "10px",
                }}
              >
                We take care of your HR needs as you concentrate on your core business.
              </Typography>
              <Link sx={{ textDecoration: "none" }} href="/contact">
                <Button
                  sx={{
                    width: { xs: "90vw", sm: "100%" },
                    // border: "1px solid #cc9048",
                    fontSize: {
                      xs: "15px",
                      sm: "16px",
                      md: "20px",
                      lg: "23px",
                    },
                    textTransform:"unset",
                    fontWeight: 400,
                    fontFamily: "Montserrat",
                    backgroundColor: "#9AA691",
                    color: "#fff",
                    p: { xs: "10px 18px", sm: "10px 45px", md: "3px 20px" },
                    mt: { sm: "5%", md: "6%" },
                    // textTransform: "unset",
                  }}
                >
                  Contact us now
                </Button>
              </Link>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Services;
