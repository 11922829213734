import React, { useEffect } from "react";
import "./service.css";
import cardData from "./cardData.json";
//import image from "../../Assets/engineer.jpg"
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import { Typography, Box, Button, Link } from "@mui/material";
import { useParams, useNavigate, useLocation } from "react-router-dom";
//import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";

const CardDetails = () => {
  const { id } = useParams();
  const url = useLocation().pathname;
  const card = cardData[id];
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component is mounted
  }, []);

  if (!card) {
    return null; // Handle the case when the cardId is invalid or not found
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box>
      <Box
        className="title-back"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: { xs: "15% 3%", md: "9%" },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "20px", sm: "30px", md: "35px" },
            color: "#fff",
          }}
        >
          {card.title}
          <hr />
        </Typography>
        <Button
          onClick={handleGoBack}
          sx={{
            // backgroundColor: "#9AA691",
            color: "#fff",
            border: "none",
            p: "0",
          }}
        >
          <CloseIcon
            sx={{ fontSize: { xs: "33px", sm: "35px", md: "50px" } }}
          />
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexDirection: {
            xs: "column-reverse",
            sm: "column-reverse",
            md: "row",
          },
          gap: "1%",
          p: { xs: "10px 2px", md: "1% 0 0 4%" },
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "50%" } }}>
          <Box sx={{ p: { xs: "5% 8%", sm: "3%", md: "5%" } }}>
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "14px", sm: "16px" },
                fontWeight: 400,
                lineHeight: "26px",
                fontFamily: "Montserrat",
                pb: "2%",
                // color: "#026302",
              }}
            >
              {card.description}
            </Typography>
            <a style={{ textDecoration: "none" }} href={`${url}/${card.title}`}>
              {card.bulletPoints.map((point, index) => (
                <Link
                  key={index}
                  to={`/card-details/${id}/bullet/${index}`}
                  style={{ textDecoration: "none", color: "#D18700" }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2px",
                      p: "0.5%",
                      cursor: "pointer",
                      fontWeight: "550",
                      lineHeight: "35px",
                      fontSize: { xs: "16px", sm: "18px" },
                    }}
                  >
                    <TrackChangesIcon
                      sx={{
                        fontSize: { xs: "18px", md: "25px" },
                        color: "green",
                      }}
                    />
                    {point}
                  </Typography>
                </Link>
              ))}
            </a>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <Button
              sx={{
                width: { xs: "90vw", sm: "80%" },
                fontFamily: "Montserrat",
                fontSize: {
                  xs: "15px",
                  sm: "16px",
                  md: "20px",
                  lg: "23px",
                },
                textTransform: "unset",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#9AA691",
                },
              }}
            >
              <a
                style={{
                  color: "#D18700",
                  textDecoration: "none",
                  fontWeight: "600",
                }}
                href={`${url}/${card.title}`}
              >
                Learn more
              </a>
            </Button>
          </Box>
        </Box>
        <Box
          className="image-container"
          sx={{ width: { xs: "100%", md: "50%" } }}
        >
          <img
            src={card.image}
            alt="this is an engineer"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default CardDetails;
