import React from "react";
import { Box, Divider, Grid, Typography } from "@mui/material";
import image from "../../Assets/section.png";
import all from "../../Assets/All the way.png";
import expert from "../../Assets/expertise.png";
import passion from "../../Assets/passion.png";
import track from "../../Assets/track record.png";
//import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

const Section = () => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: { xs: "15px", sm: "30px", md: "30px", lg: "40px" },
          flexDirection: { xs: "column", sm: "column", md: "row" },
          // backgroundColor: "#F2EADD",
        }}
      >
        <Box sx={{ width: { xs: "100%", sm: "99%", md: "50%", lg: "50%" } }}>
          <img
            src={image}
            alt="this is an "
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
        <Box
          sx={{
            width: { xs: "85%", sm: "90%", md: "50%", lg: "50%" },
            p: { xs: "1px", sm: "2%", md: "1%" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "95%", md: "90%", lg: "80%" } }}>
            <Typography
              sx={{
                color: "#9AA691",
                fontSize: { xs: "10px", sm: "15px" },
                fontWeight: 600,
                fontFamily: "Montserrat",
              }}
            >
              All Round HR solutions
            </Typography>
            <Typography
              sx={{
                fontWeight: 700,
                pb: "2%",
                // lineHeight: { xs: "35px", sm: "47px", md: "60px" },
                fontFamily: "Montserrat",
                fontSize:
                  "calc((1.1 - 1) * calc(.012 * min(100vh, 900px)) + 30px);",
              }}
            >
              The value we promise you:
            </Typography>
            {/* <Typography
              sx={{
                fontSize: { xs: "16px", sm: "20px", md: "20px", lg: "24px" },
                fontWeight: 500,
                fontFamily: "Montserrat",
              }}
            >
              Lorem ipsum is simply free text dolor sit am adipi we help you
              ensure everyone.
            </Typography> */}
            <Box>
              <Grid
                container
                spacing={3}
                sx={{ display: "flex", flexWrap: "wrap" }}
              >
                <Grid item xs={6} sm={6}>
                  <Box>
                    <img
                      src={passion}
                      alt="this is an engineer"
                      style={{
                        width: "20%",
                        height: "20%",
                        // objectFit: "cover",                       
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontFamily: "Montserrat",
                        fontSize:
                          "calc((1.1 - 1) * calc(.012 * min(100vh, 900px)) + 18px);",
                      }}
                    >
                      Passion
                    </Typography>
                    <Divider />
                    <Typography
                      sx={{
                        pt: "7px",
                        fontWeight: 400,
                        fontFamily: "Montserrat",
                        fontSize:
                          "calc((1.1 - 1) * calc(.012 * min(100vh, 900px)) + 13px);",
                      }}
                    >
                      We are dedicated to practical solutions, passionately accompanying our clients as they redefine success. Each client is unique and highly valued.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Box>
                    <img
                      src={expert}
                      alt="this is an engineer"
                      style={{
                        width: "20%",
                        height: "20%",
                        objectFit: "cover",
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontFamily: "Montserrat",
                        fontSize:
                          "calc((1.1 - 1) * calc(.012 * min(100vh, 900px)) + 18px);",
                      }}
                    >
                      Expertise
                    </Typography>
                    <Divider />
                    <Typography
                      sx={{
                        pt: "7px",
                        fontWeight: 400,
                        fontFamily: "Montserrat",
                        fontSize:
                          "calc((1.1 - 1) * calc(.012 * min(100vh, 900px)) + 13px);",
                      }}
                    >
                      Our experienced team offers world class extensive knowledge and skills, providing well-rounded approaches to address each unique need effectively.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Box>
                    <img
                      src={track}
                      alt="this is an engineer"
                      style={{
                        width: "20%",
                        height: "20%",
                        objectFit: "cover",
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontFamily: "Montserrat",
                        fontSize:
                          "calc((1.1 - 1) * calc(.012 * min(100vh, 900px)) + 18px);",
                      }}
                    >
                      Stellar Track Record
                    </Typography>
                    <Divider />
                    <Typography
                      sx={{
                        pt: "7px",
                        fontWeight: 400,
                        fontFamily: "Montserrat",
                        fontSize:
                          "calc((1.1 - 1) * calc(.012 * min(100vh, 900px)) + 13px);",
                      }}
                    >
                      Our proven, integrated approach delivers precise,  predictable and timely benefits to clients, with a track record of success.
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <Box>
                    <img
                      src={all}
                      alt="this is an engineer"
                      style={{
                        width: "20%",
                        height: "20%",
                        objectFit: "cover",
                      }}
                    />
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontFamily: "Montserrat",
                        fontSize:
                          "calc((1.1 - 1) * calc(.012 * min(100vh, 900px)) + 18px);",
                      }}
                    >
                      We go All The Way!
                    </Typography>
                    <Divider />
                    <Typography
                      sx={{
                        pt: "7px",
                        fontWeight: 400,
                        fontFamily: "Montserrat",
                        fontSize:
                          "calc((1.1 - 1) * calc(.012 * min(100vh, 900px)) + 13px);",
                      }}
                    >
                      With an unwavering commitment to delivering exceptional results, we harness a wide range of tools, techniques, and a highly qualified, motivated, and positive team to ensure measurable outcomes.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Section;
