import React from "react";
import About from "../Components/About/About";
import CallToAction from "../Components/CTA- Section/CallToAction";
import Hero1 from "../Components/Hero /Hero1";
import Section from "../Components/Section/Section";
import Services from "../Components/Services/Serve";
import Testimony from "../Components/Testimonial/Testimony";
//import Bottom from "../Components/Services/Bottom";

const Homepage = () => {
  return (
    <div>
      <Hero1 />
      <About />
      <Services />
      {/* <Bottom /> */}
      {/* <Testimony /> */}
      <Section />
      {/* <CallToAction /> */}
    </div>
  );
};

export default Homepage;
