import React from "react";
import PropTypes from "prop-types";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Container } from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import logo from "../../Assets/logo_main.jpg";
import { Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

const drawerWidth = 240;

function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func,
};

function Nav(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  let navigate = useNavigate();

  const navigateAbout = () => {
    const element = document.getElementById("about");
    navigate("/");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navigateServices = () => {
    const element = document.getElementById("services");
    navigate("/");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography
        variant="h6"
        sx={{
          my: 2,
          color: "#4E5945",
          fontWeight: "600",
          fontFamily: "Montserrat",
        }}
      >
        PHRS
      </Typography>
      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Link href="/" variant="body2" style={{ textDecoration: "none" }}>
          <Button
            sx={{
              color: "#4E5945",
              fontFamily: "Montserrat",
              fontWeight: 400,
              fontSize: "15px",
              textTransform: "capitalize",
            }}
          >
            Home
          </Button>
        </Link>
        <Link
          onClick={() => navigateAbout()}
          variant="body2"
          style={{ textDecoration: "none" }}
        >
          <Button
            sx={{
              color: "#4E5945",
              fontFamily: "Montserrat",
              fontWeight: 400,
              fontSize: "15px",
              textTransform: "capitalize",
            }}
          >
            About Us
          </Button>
        </Link>
        <Link
          onClick={() => navigateServices()}
          variant="body2"
          style={{ textDecoration: "none" }}
        >
          <Button
            sx={{
              color: "#4E5945",
              fontFamily: "Montserrat",
              fontWeight: 400,
              fontSize: "15px",
              textTransform: "capitalize",
            }}
          >
            Our Services
          </Button>
        </Link>
        {/* <Link
          onClick={() => navigateServices()}
          variant="body2"
          style={{ textDecoration: "none" }}
        >
          <Button
            sx={{
              color: "#4E5945",
              fontFamily: "Montserrat",
              fontWeight: 400,
              fontSize: "15px",
              textTransform: "capitalize",
            }}
          >
            Talent Pools
          </Button>
        </Link> */}
        <Link
          href="/contact"
          variant="body2"
          style={{ textDecoration: "none" }}
        >
          <Button
            sx={{
              color: "#4E5945",
              fontFamily: "Montserrat",
              fontWeight: 400,
              fontSize: "15px",
              textTransform: "capitalize",
            }}
          >
            Contact Us
          </Button>
        </Link>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Container
      sx={{ display: "flex", justifyContent: "space-between", height: "6vh" }}
    >
      <CssBaseline />
      <ElevationScroll {...props}>
        <AppBar
          elevation={0}
          component="nav"
          sx={{
            backgroundColor: "#F2EADD",
            // color: "#fff",
            pt: { xs: "10px", sm: "19px", md: "13px" },
          }}
        >
          <Container maxWidth="xl">
            {" "}
            <Toolbar sx={{ justifyContent: "space-between" }}>
              <a href="/" style={{ textDecoration: "none" }}>
                <Box sx={{ flex: 0.9 }}>
                  <Typography
                    variant="h3"
                    component="div"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "20px",
                    }}
                  >
                    <img src={logo} alt="gt" style={{ height: "50px" }} />

                    <Typography
                      sx={{
                        color: "#4E5945",
                        fontFamily: "Montserrat",
                        fontWeight: 700,
                        fontSize: { xs: "18px", sm: "19px", md: "30px" },
                      }}
                    >
                      Pivot HR Solutions

                    </Typography>
                  </Typography>
                </Box>
              </a>
              <Box
                sx={{
                  justifyContent: "space-evenly",
                  flex: { sm: 1.2, md: 0.8 },
                  display: { xs: "none", sm: "flex" },
                }}
              >
                <Link
                  href="/"
                  variant="body2"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    sx={{
                      display: "flex",
                      color: "#000",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      fontSize: { sm: "15px", md: "16px" },
                      textTransform: "capitalize",
                      textDecoration: "none",
                    }}
                  >
                    Home
                  </Button>
                </Link>

                <Link
                  onClick={() => navigateAbout()}
                  variant="body2"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    sx={{
                      display: "flex",
                      color: "#000",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      fontSize: { sm: "15px", md: "16px" },
                      textTransform: "capitalize",
                      textDecoration: "none",
                    }}
                  >
                    About_Us
                  </Button>
                </Link>
                <Link
                  onClick={() => navigateServices()}
                  variant="body2"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    sx={{
                      display: "flex",
                      color: "#000",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      fontSize: { sm: "15px", md: "16px" },
                      textTransform: "capitalize",
                      textDecoration: "none",
                    }}
                  >
                    Our_Services
                  </Button>
                </Link>
                {/* <Link
                  onClick={() => navigateServices()}
                  variant="body2"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    sx={{
                      display: "flex",
                      color: "#000",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      fontSize: { sm: "15px", md: "16px" },
                      textTransform: "capitalize",
                      textDecoration: "none",
                    }}
                  >
                    Talent_Pools
                  </Button>
                </Link> */}
                <Link
                  href="/contact"
                  variant="body2"
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    sx={{
                      display: "flex",
                      color: "#000",
                      fontFamily: "Montserrat",
                      fontWeight: 500,
                      fontSize: { sm: "15px", md: "16px" },
                      textTransform: "capitalize",
                      textDecoration: "none",
                    }}
                  >
                    Contact_Us
                  </Button>
                </Link>
              </Box>

              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ ml: "30%", display: { sm: "none", color: "#000" } }}
              >
                <MenuIcon />
              </IconButton>

              {/* <Box sx={{textAlign:"right"}}>
                <Button
                  sx={{
                    ml:{xs:"23vw"},
                    width:"110px",
                    border: "1px solid #9e7a47",
                    backgroundColor: "#FFC558",
                    color: "#A06800",
                  }}
                >
                  Contact Us
                </Button>
              </Box> */}
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          anchor="right"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Container>
  );
}

Nav.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Nav;
