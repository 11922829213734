import React from "react";
import cardData from "./cardData.json";
import "./service.css";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import StarRateIcon from '@mui/icons-material/StarRate';

const ServicesContent = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const card = cardData[id];
  if (!card) {
    return null; // Handle the case when the cardId is invalid or not found
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <Box sx={{ width: "100vw" }}>
      <Box
        // className="title-back"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: { xs: "2% 1%",sm:"4% 2% 3% 2%", md: "4% 20px" },
          
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: "20px", sm: "30px", md: "35px" },
            fontWeight: "800",
            color: "#3b4a2f",
          }}
        >
          {card.title}
        </Typography>
        <Button
          onClick={handleGoBack}
          sx={{
            // backgroundColor: "#9AA691",
            // color: "#fff",
            border: "none",
            p: "0",
          }}
        >
          <CloseIcon
            sx={{ fontSize: { xs: "33px", sm: "35px", md: "50px" }, color:"#3b4a2f" }}
          />
        </Button>
      </Box>
      <Box sx={{ }}>
        <Grid
          sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item xs={8}>
            <Box sx={{ display: "flex", flexWrap: "wrap", pl:"2vw" }}>
              {card.content.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    width: { xs: "95%", sm: "50%", md: "40%", lg: "40%" },
                    p: { xs: "10px 5px", sm: "12px 6px", md:"10px 1px" },
                  }}
                >
                  <StarRateIcon sx={{color:"#3b4a2f"}} />
                  <Typography
                    sx={{
                      fontSize: { xs: "15px", sm:"18px",md: "18px" },
                      fontWeight: 600,
                      fontFamily: "Montserrat",
                      color: "#D18700",
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    sx={{
                      width: { xs: "100%", sm: "90%", md: "85%", lg: "80%" },
                      fontSize: { xs: "14px", sm:"15px",md: "16px" },
                    }}
                  >
                    {item.description}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ width:{xs:"100%", sm:"none",md:"15vw"} }}>
              <img
                src={card.image}
                alt="corner"
                style={{
                  width: "100%",
                  height: "70vh",
                  objectFit: "cover",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ServicesContent;
