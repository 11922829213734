import React from 'react'
import Contact from '../Components/ContactUs/Contact'



const ContactUs = () => {
  return (
    <div>
        <Contact />
    </div>
  )
}

export default ContactUs