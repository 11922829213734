import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
//import 'swiper/css/loop';
import "./swiperStyles.css";

import "./hero.css";
import { Box, Button, Link, Typography} from "@mui/material";

const Hero1 = () => {
  return (
    <Swiper
      modules={[Navigation, Autoplay, Pagination]}
      spaceBetween={50}
      slidesPerView={1}
      pagination={true}
      autoplay={{
        delay: 5000, // Set autoplay delay to 30 seconds (30,000 milliseconds)
        disableOnInteraction: false, // Keep autoplay even when user interacts with slides
      }}
      loop={true}
    //   navigation
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
      style={{ height: "95vh" }} // Adjust the height of the Swiper component
    >
      <SwiperSlide>
        <Box className="hero-background">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "95vh",
            }}
          >
            <Box>
            <Typography
                sx={{
                  color: "#fff",
                  fontSize: { xs: "32px", sm: "35px", md: "50px", lg: "53px" },
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
                Unlocking Potential and Driving<br /> Organizational Excellence.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection:{xs: "column", sm: "row"},
                justifyContent: "space-evenly",
                alignItems: "center",
                gap: { xs: "20px", sm: "170px", md:"200px" },
                mt: {xs:"50px", sm:"30px"},
              }}
            >
              <Link sx={{ textDecoration: "none" }} href="#services">
                <Button
                  className="btn"
                  sx={{
                    width: { xs: "90vw", sm: "100%" },
                    fontFamily: "Montserrat",
                    fontSize: { xs: "15px", sm: "16px", md: "23px" },
                    backgroundColor: "#D18700",
                    color: "#fff",
                    p: {
                      xs: "8px 18px",
                      sm: "10px 45px",
                      md: "6px 18px",
                      lg: "8px 20px",
                    },
                  }}
                >
                  Learn More
                </Button>
              </Link>
              <Link sx={{ textDecoration: "none" }} href="/contact">
                <Button
                  className="btn"
                  sx={{
                    width: { xs: "90vw", sm: "100%" },
                    fontFamily: "Montserrat",
                    fontSize: { xs: "15px", sm: "16px", md: "23px" },
                    backgroundColor: "#D18700",
                    color: "#fff",
                    p: {
                      xs: "8px 18px",
                      sm: "10px 45px",
                      md: "6px 22px",
                      lg: "8px 25px",
                    },
                  }}
                >
                  Contact Us
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </SwiperSlide>
      <SwiperSlide>
        <Box className="hero2-background">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "95vh",
            }}
          >
            <Box>
            <Typography
                sx={{
                    color: "#fff",
                  fontSize: { xs: "32px", sm: "35px", md: "50px", lg: "53px" },
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
               Igniting Change, Inspiring Results.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection:{xs: "column", sm: "row"},
                justifyContent: "space-evenly",
                alignItems: "center",
                gap: { xs: "20px", sm: "170px", md:"200px" },
                mt: {xs:"50px", sm:"30px"},
              }}
            >
              <Link sx={{ textDecoration: "none" }} href="#services">
                <Button
                  className="btn"
                  sx={{
                    width: { xs: "90vw", sm: "100%" },
                    fontFamily: "Montserrat",
                    fontSize: { xs: "15px", sm: "16px", md: "23px" },
                    backgroundColor: "#D18700",
                    color: "#fff",
                    p: {
                      xs: "8px 18px",
                      sm: "10px 45px",
                      md: "6px 18px",
                      lg: "8px 20px",
                    },
                  }}
                >
                  Learn More
                </Button>
              </Link>
              <Link sx={{ textDecoration: "none" }} href="/contact">
                <Button
                  className="btn"
                  sx={{
                    width: { xs: "90vw", sm: "100%" },
                    fontFamily: "Montserrat",
                    fontSize: { xs: "15px", sm: "16px", md: "23px" },
                    backgroundColor: "#D18700",
                    color: "#fff",
                    p: {
                      xs: "8px 18px",
                      sm: "10px 45px",
                      md: "6px 22px",
                      lg: "8px 25px",
                    },
                  }}
                >
                  Contact Us
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </SwiperSlide>
      <SwiperSlide>
        <Box className="hero3-background">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "95vh",
            }}
          >
            <Box>
            <Typography
                sx={{
                    color: "#fff",
                  fontSize: { xs: "32px", sm: "35px", md: "50px", lg: "53px" },
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  textAlign: "center",
                }}
              >
              
              Strategic HR Solutions Tailored to<br />  your Unique Needs.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection:{xs: "column", sm: "row"},
                justifyContent: "space-evenly",
                alignItems: "center",
                gap: { xs: "20px", sm: "170px", md:"200px" },
                mt: {xs:"50px", sm:"30px"},
              }}
            >
              <Link sx={{ textDecoration: "none" }} href="#services">
                <Button
                  className="btn"
                  sx={{
                    width: { xs: "90vw", sm: "100%" },
                    fontFamily: "Montserrat",
                    fontSize: { xs: "15px", sm: "16px", md: "23px" },
                    backgroundColor: "#D18700",
                    color: "#fff",
                    p: {
                      xs: "8px 18px",
                      sm: "10px 45px",
                      md: "6px 18px",
                      lg: "8px 20px",
                    },
                  }}
                >
                  Learn More
                </Button>
              </Link>
              <Link sx={{ textDecoration: "none" }} href="/contact">
                <Button
                  className="btn"
                  sx={{
                    width: { xs: "90vw", sm: "100%" },
                    fontFamily: "Montserrat",
                    fontSize: { xs: "15px", sm: "16px", md: "23px" },
                    backgroundColor: "#D18700",
                    color: "#fff",
                    p: {
                      xs: "8px 18px",
                      sm: "10px 45px",
                      md: "6px 22px",
                      lg: "8px 25px",
                    },
                  }}
                >
                  Contact Us
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </SwiperSlide>
      <SwiperSlide>
        <Box className="hero4-background">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "95vh",
            }}
          >
            <Box>
              <Typography
                sx={{
                    color: "#fff",
                  fontSize: { xs: "32px", sm: "35px", md: "50px", lg: "53px" },
                  fontFamily: "Montserrat",
                  fontWeight: 500,
                  textAlign: "center",
                //   p:{xs:"1px 3%", md:"1px"}
                }}
              >
                Empowering Individuals to
                <br /> Reach their Career Potential.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection:{xs: "column", sm: "row"},
                justifyContent: "space-evenly",
                alignItems: "center",
                gap: { xs: "20px", sm: "170px", md:"200px" },
                mt: {xs:"50px", sm:"30px"},
              }}
            >
              <Link sx={{ textDecoration: "none" }} href="#services">
                <Button
                  className="btn"
                  sx={{
                    width: { xs: "90vw", sm: "100%" },
                    fontFamily: "Montserrat",
                    fontSize: { xs: "15px", sm: "16px", md: "23px" },
                    backgroundColor: "#D18700",
                    color: "#fff",
                    p: {
                      xs: "8px 18px",
                      sm: "10px 45px",
                      md: "6px 18px",
                      lg: "8px 20px",
                    },
                  }}
                >
                  Learn More
                </Button>
              </Link>
              <Link sx={{ textDecoration: "none" }} href="/contact">
                <Button
                  className="btn"
                  sx={{
                    width: { xs: "90vw", sm: "100%" },
                    fontFamily: "Montserrat",
                    fontSize: { xs: "15px", sm: "16px", md: "23px" },
                    backgroundColor: "#D18700",
                    color: "#fff",
                    p: {
                      xs: "8px 18px",
                      sm: "10px 45px",
                      md: "6px 22px",
                      lg: "8px 25px",
                    },
                  }}
                >
                  Contact Us
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </SwiperSlide>
      ...
    </Swiper>
  );
};

export default Hero1;
