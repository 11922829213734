import React from "react";
import { Box, Typography } from "@mui/material";
import about from "../../Assets/aabt.jpg";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const About = () => {
  return (
    <Box
      id="about"
      sx={{
        
        p: "2% 1px 5% 1px",
      }}
    >
      <Box sx={{pb:{xs:"15px", md:"20px"}}}>
        <Typography
          sx={{
            fontSize: { xs: "25px", sm: "40px", md: "43px" },
            fontWeight: 700,
            textAlign: "center",
            fontFamily: "Montserrat",
            
          }}
        >
          About Pivot HR Solutions
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          gap: "18px",
        }}
      >
        <Box sx={{ width: { xs: "90%", md: "40%" } }}>
          <Typography
            sx={{
              fontSize: "calc((1.1 - 1) * calc(.012 * min(100vh, 900px)) + 13px);",
              fontFamily: "Montserrat",
              fontWeight: 500,
              // lineHeight:"23px",
              textAlign:"block",
              p: {xs:"2px",md:"10px 2px"}
            }}
          >
            We are a dedicated team of professionals specializing in providing strategic HR solutions to empower individuals and organizations. Our approach is centered around creating tailored solutions to align with your  personal and / or organizational  unique goals and challenges.<br /> <br />
            We excel in Talent Acquisition, Software Developers Services and Comprehensive Integrated HR Advisory Services such as  Training, Human Capital advisory services, Management advisory services, Talent Strategy development and implementation, and Organizational Capability assessments. We offer a comprehensive range of services designed to optimize the full potential of your most valuable asset - your staff!
            <br /><br />
            We also guide professionals transition and advance their careers by showcasing their skills and experience to potential employers through our career advancement services.

          </Typography>
        </Box>
        <Box sx={{ width: { xs: "98%", md: "50%"} }}>
          <img
            src={about}
            alt="gt"
            style={{ width: "100%", height: "100%", borderRadius: "5px" }}
          />
        </Box>
        {/* <Box sx={{ width: { xs: "90%", md: "30%" } }}>
          <Typography
            sx={{
              fontSize: "calc((1.1 - 1) * calc(.012 * min(100vh, 900px)) + 13px);",
              fontFamily: "Montserrat",
              fontWeight: 400,
              p: {xs:"2px",md:"10px 2px"}
            }}
          >
            Pivot Works HR Solutions excels in recruiting top-tier mid to
            senior-level executives for various sectors, including public,
            private, and non-governmental organizations. Our unique approach
            ensures access to high-caliber talent and a rigorous methodology for
            identifying ideal candidates ensuring the perfect match for each
            position.
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
};

export default About;
