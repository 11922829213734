import React from "react";
import "./App.css";
import Homepage from "./Pages/Homepage";
import { Route, Routes } from "react-router-dom";
import ContactUs from "./Pages/ContactUs";
import Nav from "./Components/NavBar/Nav";
import Footer from "./Components/Footer/Footer";
import CardDetails from "./Components/Services/CardDetails";
import ServicesContent from "./Components/Services/ServicesContent";

function App() {
  return (
    <div className="App">
      <Nav />

      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route exact path="/contact" element={<ContactUs />} />
        <Route path="/card-details/:id" element={<CardDetails />} />
        <Route path="/card-details/:id/:title" element={<ServicesContent />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
